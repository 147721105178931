import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    profile: null,
    ekycData: null,
    documents: null,
    images: {
        front: null,
        back: null,
        face: null
    },
    uploadedImages: {
        front: null,
        back: null,
        face: null
    },
    documentsViewed: {
        contract: false,
        documents: false
    }
}

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setImage: (state, action) => {
            const { value, side } = action.payload
            state.images[side] = value
        },
        setUploadedImage: (state, action) => {
            const { value, side } = action.payload
            state.uploadedImages[side] = value
        },
        setEkycData: (state, action) => {
            state.ekycData = action.payload
        },

        setDocumentViewed: (state, action) => {
            const data = action.payload
            if (Object(data).hasOwnProperty('contract')) {
                state.documentsViewed.contract = data.contract
            }
            if (Object(data).hasOwnProperty('documents')) {
                state.documentsViewed.documents = data.documents
            }
        },
        
        setDocuments: (state, action) => {
            const { documents } = action.payload
            state.documents = documents || []
        },
    }
})

export const selectProfile = (state) => state.config.profile
export const selectDocumentViewed = (state) => state.config.documentsViewed
export const selectDocuments = (state) => state.config.documents
export const { setProfile, setImage, setUploadedImage, setEkycData, setDocumentViewed, setDocuments } = configSlice.actions

export default configSlice.reducer
